<template>
	<MhRouterView class="RbShopView view" :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu />

		<RbFilterBar
			v-if="post"
			:title="'Rubriken'"
			:filters="_.get( post, 'acf.filterLegend__rubriken' )"
		/>
		<RbFilterBar
			v-if="post"
			:title="'Friends'"
			:filters="_.get( post, 'acf.filterLegend__friends' )"
		/>

		<article class="view__inner hSpace hSpace--app">

			<br/><br/>

			<!--
				<pre name="isSearch">{{isSearch}}</pre>
				<pre name="hasFilter">{{hasFilter}}</pre>
			-->

			<div class="RbShopView__noSearchResults" v-if="isSearch && !products.length">
				Unter dem Suchbegriff »{{$route.query.search}}« wurden keine Produkte gefunden.
			</div>

			<div class="RbShopView__productsGrid">

				<RbProductTeaser
					v-if="!isSearch && !hasFilter"
					:text="_.get( post, 'acf.infoText', false )"
				></RbProductTeaser>

				<RbProductTeaser
					v-for="(product, index) in products" :key="index"
					:product="product"
				></RbProductTeaser>

			</div>

			<!--
			<pre name="products">{{products}}</pre>
			-->

		</article>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	import RbAppHeader from "@/components/RbAppHeader.vue";
	import RbAppMenu from "@/components/RbAppMenu.vue";
	import RbProductTeaser from '@/components/RbProductTeaser.vue'
	import RbFilterBar from "@/components/RbFilterBar.vue"
	import RbShopMixin from '@/components/RbShop/RbShop.mixin.js'
	import RbAppFooter from "@/components/RbAppFooter.vue"

	export default {
		name: 'RbShopView',
		components: {
			MhRouterView,
			RbAppHeader,
			RbAppMenu,
			RbFilterBar,
			RbProductTeaser,
			RbAppFooter,
		},
		mixins: [
			RestHandler,
			RbShopMixin,
		],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady : false,
				post           : {},
				products       : [],
			}
		},
		watch: {
			'$route.query': {
				handler: function( to, from ) {
					let fetchParams = {}

					//console.log('$route.query', from, to)

					if( this._.get( to, 'search') ) fetchParams = { search : to.search }
					if( this._.get( to, 'filterId') ) fetchParams = { filterId : to.filterId }

					this.fetchAll( fetchParams, ()=>{
						this.isContentReady = true
					})
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			isSearch(){
				return this._.has( this.$route, 'query.search' )
			},
			hasFilter(){
				return this._.has( this.$route, 'query.filterId' )
			},
		},
		methods: {
			fetchAll( params, finalCallback ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch shop page
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						//slug : this.$route.params.slug,
						slug : 'shop',
					},
					callback : (response) => {
						this.post = response.data.result[0]

						runCallback()
					},
				})

				// fetch and normalize products
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/products',
					params : params ? params : {},
					callback : (response) => {
						const results = response.data.result
						const normalizedProducts = []

						// normalize all products from db
						this._.forEach(results, (product)=>{
							normalizedProducts.push( this.normalizeProduct(product) )
						})

						this.products = normalizedProducts

						runCallback()
					},
				})
			},
		},
		mounted(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.RbShopView {}
	.RbShopView { // layout
		&__productsGrid {
			.grid;
			.grid--cols-12;
			.grid--colGap;
			.grid--rowGap;

			.RbProductTeaser {
				grid-column: span 3;

				@media @mq[md] {
					grid-column: span 6;
				}
			}
		}
	}
	.RbShopView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
